(() => {
  const arcs = Array.from(document.querySelectorAll(".arc-wipe"));

  const setArcParams = () => {
    arcs.forEach(a => {
      const rect = a.getBoundingClientRect();
      const circle = a.querySelector("circle");
      const c = 2 * Math.PI * (rect.width/2);
      circle.style.strokeDasharray = c;
      circle.style.strokeDashoffset = c;
    })
  }

  setArcParams();

  window.addEventListener("resize",setArcParams);

})()
